<template>
  <div
    v-if="isLoggedIn && isAdvisorAssigned"
    class="bg-blue-600 px-5 py-3"
    data-el="t3-ce-rawlplug-accountperson"
  >
    <p
      v-if="props.label"
      class="mb-2.5 flex items-center justify-between gap-2 text-base font-bold text-white"
    >
      <template v-if="formattedAdviserData?.fullname">
        {{ formattedAdviserData.fullname }}
      </template>
      <span class="text-sm font-normal text-grey">{{ props.label }}</span>
    </p>

    <ul
      v-if="
        formattedAdviserData?.phone
          || formattedAdviserData?.email
      "
      :class="styles.ul"
    >
      <li
        v-if="formattedAdviserData?.phone"
        :class="styles.li"
      >
        <a
          :href="`tel:${formattedAdviserData.phone}`"
          :class="styles.link"
        >
          <UiIcon
            name="phone"
            v-bind="iconsSize"
          />
          {{ formattedAdviserData.phone }}
        </a>
      </li>
      <li
        v-if="formattedAdviserData?.email"
        :class="styles.li"
      >
        <a
          :href="`mailto:${formattedAdviserData.email}`"
          :class="styles.link"
        >
          <UiIcon
            name="envelope"
            v-bind="iconsSize"
          />
          {{ formattedAdviserData.email }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useCustomer } from '@customer/composables/useCustomer'
import type { T3CeRawlplugAccountPersonProps } from './T3CeRawlplug_accountperson.types'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<T3CeRawlplugAccountPersonProps>(), {
  label: '',
})

const { isLoggedIn, formattedAdviserData, isAdvisorAssigned } = useCustomer()

const iconsSize = { width: 15, height: 15 }

const styles = {
  ul: 'text-white flex flex-col gap-2.5 text-sm',
  li: 'flex items-center gap-2.5 whitespace-nowrap',
  link: 'flex items-center gap-3.5',
}
</script>
